import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { Subscribe } from './index';
import { SubscribeModal } from '../../common/components/subscribe-modal';
import '~/styles/main.scss';

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.Subscribe = Subscribe;
global.SubscribeModal = SubscribeModal;
