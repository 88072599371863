import React, { CSSProperties, useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { Link } from '~/common/models';
import { Button, ButtonTypeEnum } from '~/common/components/ui-elements';
import {
  GetLocalHasSubscribed,
  GetSessionPageVisited,
  GetSessionPopupHide,
  SetSessionPopupHide,
} from '~/common/utils/session';
import { validateEmail } from '~/common/utils/validations';
import { hexToRgbA } from '~/common/utils';
import { SvgIcon } from '../ui-elements/svg-icon';

interface ISubscribeModal {
  backgroundColor?: string;
  backgroundImage?: string;
  content?: string;
  fontColor?: string;
  title?: string;
  contentAlignment?: any;
  isBackgroundGradient?: boolean;
  popupCTA?: Link & {
    format?: keyof typeof ButtonTypeEnum;
    size?: 'small' | 'medium' | 'large';
  };
}

export const SubscribeModal = React.memo(() => {
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isPopupEmailValid, setIsPopupEmailValid] = useState(true);
  const [email, setEmail] = useState('');
  const [data, setData] = useState<ISubscribeModal | undefined>();
  const [backgroundStyle, setBackgroundStyle] = useState<CSSProperties>();

  const waitUntil = (callback, time) => {
    const intervalId = setInterval(() => {
      clearInterval(intervalId);
      callback();
    }, time);
  };

  const deActivatePopup = () => {
    if (isPopupActive) {
      SetSessionPopupHide();
      setIsPopupActive(false);
    }
  };

  const activatePopup = () => {
    if (!isPopupActive) {
      setIsPopupActive(true);
    }
  };

  const doWeActivatePopup = () => {
    // rules for popup activation
    const visitedCount = GetSessionPageVisited();
    const popupHide = GetSessionPopupHide();
    const hasSubscribed = GetLocalHasSubscribed();
    if (!popupHide && visitedCount > 2 && !hasSubscribed) {
      activatePopup();
    }
  };

  const trySubmit = (emailAddress: string, url: string) => {
    setIsPopupEmailValid(validateEmail(emailAddress));
    if (validateEmail(emailAddress)) {
      window.sessionStorage.setItem('userEmail', emailAddress);
      window.location.href = url;
    }
  };

  const emailInput = ({ target: { value } }) => {
    setIsPopupEmailValid(validateEmail(value));
    setEmail(value);
  };

  useEffect(() => {
    if (!data) return;
    let _backgroundStyle: CSSProperties;

    if (!!data.backgroundColor) {
      if (data.isBackgroundGradient) {
        _backgroundStyle = {
          backgroundImage: `linear-gradient(to top, ${hexToRgbA(
            data.backgroundColor,
            '0'
          )},${data.backgroundColor},${data.backgroundColor},${
            data.backgroundColor
          })`,
          color: data.fontColor,
        };
      } else {
        _backgroundStyle = {
          backgroundColor: data.backgroundColor,
          color: data.fontColor,
        };
      }
    } else {
      _backgroundStyle = {
        backgroundImage:
          'linear-gradient(to top, rgba(255,255,255,0),white,white,white)',
        color: data.fontColor,
      };
    }
    setBackgroundStyle(_backgroundStyle);
  }, [data]);

  useEffect(() => {
    waitUntil(doWeActivatePopup, 2000);
    setEmail(window.sessionStorage.getItem('userEmail') || '');

    const _data: ISubscribeModal = window['SubscribePopupModel'];
    if (_data) setData(_data);
  }, []);

  return (
    <div
      className={cn(styles.SubscribeNowPopup, {
        [styles.Active]: isPopupActive,
        [styles.Inactive]: !isPopupActive,
      })}
    >
      {data && (
        <div className={styles.MainWrapper}>
          <div className={styles.Overlay} onClick={deActivatePopup} />
          <div
            className={styles.Wrapper}
            style={{
              background: `url(${data.backgroundImage})`,
              backgroundSize: 'contain',
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className={styles.Container}>
              <div className={styles.Close} onClick={deActivatePopup}>
                <SvgIcon type="close" />
              </div>
              <div className={styles.Content} style={backgroundStyle}>
                <h3
                  style={{
                    color: data.fontColor,
                  }}
                >
                  {data.title}
                </h3>
                <div
                  dangerouslySetInnerHTML={{ __html: data.content as string }}
                  style={{
                    textAlign: !!data.content
                      ? data.contentAlignment
                      : 'center',
                  }}
                />
                <div className={styles.SignForm}>
                  {data.popupCTA && (
                    <div
                      className={cn(styles.SignUp, {
                        [styles.HasError]: !isPopupEmailValid,
                      })}
                    >
                      <div className={styles.InputBox}>
                        <input
                          className={cn({
                            [styles.InputHasError]: !isPopupEmailValid,
                          })}
                          placeholder="Enter your email address…"
                          type="email"
                          value={email}
                          onChange={(e) => {
                            emailInput(e);
                          }}
                        />
                        <span
                          className={cn({
                            [styles.ErrorMessage]: !isPopupEmailValid,
                            [styles.ErrorMessageActive]: !isPopupEmailValid,
                          })}
                          style={{
                            display: !isPopupEmailValid ? 'block' : 'none',
                          }}
                        >
                          Please provide a valid email address.
                        </span>
                      </div>

                      <div className={styles.ButtonBox}>
                        <Button
                          type={data.popupCTA.format}
                          size={16}
                          buttonSize={data.popupCTA.size}
                          onClick={() =>
                            trySubmit(email, data.popupCTA?.url || '/subscribe')
                          }
                        >
                          {data.popupCTA.name}
                        </Button>
                      </div>
                    </div>
                  )}
                  <a href="/privacy-policy" style={{ color: data.fontColor }}>
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
