import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { Link } from '~/common/models';
import { useMediaQuery } from 'react-responsive';
import { colorError, colorWhite, screenMd } from '~/common/utils/constants';
import { mapBackgroundColor } from '../hero-card-v2/common';
import { ILayout } from '../hero-card-v2/store';
import { Button, ButtonElement } from '~/common/components/ui-elements';
import { validateEmail } from '~/common/utils/validations';
import { hexToRgbA } from '~/common/utils';

interface ISubscribe {
  id: number;
  header?: string;
  content?: string;
  fontColor?: string;
  sectionColor?: string;
  backgroundColor?: string;
  gradientBackgroundColor?: boolean;
  cta?: Link;
  background?: string;
  backgroundMobile?: string;
}

export const Subscribe = React.memo((props: ISubscribe) => {
  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { maxDeviceWidth: screenMd },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );
  const [email, setEmail] = React.useState<string>('');
  const [backgroundDetails, setBackgroundDetails] = React.useState<ILayout>({
    backgroundColor: props.backgroundColor,
    backgroundColorGradient: 'left',
  } as ILayout);
  const [imageSrc, setImageSrc] = React.useState<string | undefined>(
    props.background
  );
  const [isPopupEmailValid, setIsPopupEmailValid] = useState(true);

  const onEmailChange = (value: string) => {
    setIsPopupEmailValid(validateEmail(value));
    setEmail(value);
  };

  const searchSubmit = (e: React.MouseEvent<ButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      window.sessionStorage.setItem('userEmail', email);
      window.location.href = props.cta?.url || '/subscribe';
    }
  };

  useEffect(() => {
    setImageSrc(!isMobile ? props.background : props.backgroundMobile);
    setBackgroundDetails({
      backgroundColor: props.backgroundColor,
      backgroundColorGradient: !isMobile ? 'left' : 'bottom',
    } as ILayout);
  }, [isMobile]);

  return (
    <div
      id={`${props.id}`}
      className={cn(styles.Subscribe)}
      style={{
        ...(props.sectionColor
          ? {
              backgroundColor: `#${props.sectionColor || colorWhite}`,
            }
          : {}),
      }}
    >
      <div className={styles.SubscribeContainer}>
        {imageSrc && <img src={imageSrc} loading="lazy" />}
        {backgroundDetails && (
          <div
            className={styles.SubscribeBackground}
            style={{
              ...(props.gradientBackgroundColor
                ? mapBackgroundColor(backgroundDetails)
                : {}),
            }}
          >
            <div className={styles.SubscribeContent}>
              <div
                style={{
                  color: `#${props.fontColor || colorWhite}`,
                }}
                dangerouslySetInnerHTML={{
                  __html: `${props.header}` as string,
                }}
              />
              <div
                style={{
                  color: `#${props.fontColor || colorWhite}`,
                }}
                dangerouslySetInnerHTML={{
                  __html: `${props.content}` as string,
                }}
              />
              <div className={styles.InputGroup}>
                <input
                  className={cn({
                    [styles.InputHasError]: !isPopupEmailValid,
                  })}
                  name="search"
                  type="text"
                  placeholder="Your email"
                  maxLength={80}
                  value={email}
                  onChange={(e) => onEmailChange(e.target.value)}
                />
                <Button
                  rounded={isMobile}
                  style={{
                    color: `#${props.fontColor || colorWhite}`,
                  }}
                  onClick={searchSubmit}
                >
                  {props.cta?.name && !props.cta?.name.includes('/')
                    ? props.cta?.name
                    : 'Sign-up'}
                </Button>
                <span
                  className={cn({
                    [styles.ErrorMessage]: !isPopupEmailValid,
                    [styles.ErrorMessageActive]: !isPopupEmailValid,
                  })}
                  style={{
                    display: !isPopupEmailValid ? 'block' : 'none',
                  }}
                >
                  Please provide a valid email address.
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
