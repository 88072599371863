import { z } from 'zod';

const nameRegex = /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,0-9]{1,30}$/;

const trimString = (u: unknown) => (typeof u === 'string' ? u.trim() : u);

export const name = (message = 'This field is empty or invalid.') =>
  z.preprocess(
    trimString,
    z
      .string()
      .max(30)
      .refine((val) => nameRegex.test(val), { message })
  );

export const nameNullable = (message = 'This field is empty or invalid.') =>
  z.preprocess(
    trimString,
    z
      .string()
      .max(30)
      .nullish()
      .refine((val) => val && nameRegex.test(val), { message })
  );

export const required = (message = 'This field is required.', max = 100) =>
  z.preprocess(
    trimString,
    z
      .string()
      .max(max, {
        message: `The maximum legth of this field is ${max} characters.`,
      })
      .min(1, { message })
  );

// required yet nullable to allow initial values of null or undefined
export const requiredNullable = (
  message = 'This field is required.',
  max = 100
) =>
  z.preprocess(
    trimString,
    z
      .string()
      .max(max, {
        message: `The maximum legth of this field is ${max} characters.`,
      })
      .nullish()
      .refine((value) => value && value.length > 0, {
        message,
      })
  );

export const emailAddress = (message = 'Please enter a valid email address') =>
  z.string().email({ message });

export const trueOnly = (message = 'Invalid') =>
  z.boolean().refine((val) => val === true, {
    message,
  });

const createRegexSchema = (regex: RegExp, message = 'Invalid format') =>
  z.string().regex(regex, { message });

const emailRegex = new RegExp(
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
);
export const validateEmail = (email: string) => emailRegex.test(email);

const phoneNumberRegex =
  /^((\+\d{1,3}(-| )?\(?\d{1,3}\)?(-| )?\d{0,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5})?$/;

export const phoneNumberSchema = createRegexSchema(
  phoneNumberRegex,
  'Please enter a valid phone number'
);
export const emailAddressSchema = createRegexSchema(
  emailRegex,
  'Please enter a valid email address'
);
export const validatePhone = (phone: string) => phoneNumberRegex.test(phone);

export { z };
