import { CSSProperties } from 'react';
import { ILayout } from './store';
import { hexToRgbA } from '~/common/utils';

export const mediaAnimationProps = (visible?: boolean) => ({
  delay: 300,
  from: { transform: 'translateY(30%)', opacity: 0 },
  to: { transform: 'translateY(0)', opacity: 1 },
  reverse: !visible,
});

export const mapBlockAlignment = (layout: ILayout): CSSProperties => {
  if (layout.position === 'absolute' && !layout.fitContent) {
    if (layout.flowDirection === 'column') {
      switch (layout.alignment?.toLowerCase()) {
        case 'end':
          return { bottom: 0 };
        case 'center':
          return {
            top: '50%',
            transform: 'translateY(-50%)',
          };
        case 'start':
          return { top: 0 };
        default:
          return {};
      }
    } else {
      switch (layout.alignment?.toLowerCase()) {
        case 'end':
          return { right: 0 };
        case 'center':
          return {
            left: '50%',
            transform: 'translateX(-50%)',
          };
        case 'start':
          return { left: 0 };
        default:
          return {};
      }
    }
  }

  switch (layout.alignment?.toLowerCase()) {
    case 'end':
      return { alignContent: 'flex-end', justifyContent: 'flex-end' };
    case 'center':
      return { alignContent: 'center', justifyContent: 'center' };
    case 'start':
      return { alignContent: 'flex-start', justifyContent: 'flex-start' };
    default:
      return {};
  }
};

export const mapBackgroundColor = (layout: ILayout): CSSProperties => {
  if (!layout.backgroundColor) return {};

  if (
    !layout.backgroundColorGradient ||
    layout.backgroundColorGradient === '' ||
    layout.backgroundColorGradient === 'none'
  ) {
    return {
      backgroundColor: `${hexToRgbA(
        layout.backgroundColor,
        `${(layout.backgroundColorOpacity || 100) / 100}`
      )}`,
    };
  }

  switch (layout.backgroundColorGradient?.toLowerCase()) {
    case 'center':
      return {
        background: `linear-gradient(
          to ${layout.flowDirection === 'column' ? 'top' : 'left'},
          transparent 0%,
          ${hexToRgbA(
            `${layout.backgroundColor}`,
            `${(layout.backgroundColorOpacity || 100) / 100}`
          )} 49%,
          ${hexToRgbA(
            `${layout.backgroundColor}`,
            `${(layout.backgroundColorOpacity || 100) / 100}`
          )} 51%,
          transparent 100%
        )`,
      };
    default:
      return {
        background: `linear-gradient(
          to ${layout.backgroundColorGradient},
          transparent 0%,
          ${hexToRgbA(
            `${layout.backgroundColor}`,
            `${(layout.backgroundColorOpacity || 100) / 100}`
          )} 100%
        )`,
      };
  }
};

export const mapDividerPosition = (layout: ILayout) => {
  if (!layout.divider) return '';
  switch (layout.divider?.toLowerCase()) {
    case 'left':
      return `width: 1px;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);`;
    case 'right':
      return `width: 1px;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);`;
    case 'bottom':
      return `height: 1px;
      bottom: 0;`;
    case 'top':
      return `height: 1px;
      top: 0;`;
    default:
      return '';
  }
};

export const mapDividerAlignment = (layout: ILayout) => {
  if (
    !(
      layout.dividerAlignment &&
      layout.divider &&
      ['top', 'bottom'].includes(layout.divider?.toLowerCase())
    )
  )
    return '';

  switch (layout.dividerAlignment?.toLowerCase()) {
    case 'left':
      return `place-self: start;`;
    case 'right':
      return `place-self: end;`;
    case 'center':
      return `place-self: center;`;
    default:
      return '';
  }
};

export const mapDividerLength = (layout: ILayout): string => {
  if (!(layout.divider && layout.dividerLength)) return '';
  switch (layout.divider?.toLowerCase()) {
    case 'left':
    case 'right':
      return `height: ${layout.dividerLength};`;
    case 'bottom':
    case 'top':
      return `width: ${layout.dividerLength};`;
    default:
      return '';
  }
};

export const mapDivider = (layout: ILayout): string => {
  return `.${`HeroCardLayoutKey-${layout.key}`}::before {
    content: '';
    position: absolute;
    ${mapDividerPosition(layout)}
    ${layout.dividerColor ? `background-color: #${layout.dividerColor};` : ''}
    ${mapDividerAlignment(layout)}
    ${mapDividerLength(layout)}
  }`;
};

export const mapButtonSizeStyle = (cta: string): CSSProperties => {
  switch (cta.toLowerCase()) {
    case 'small':
      return { minWidth: 'calc(25% - 10px)' };
    case 'medium':
      return { minWidth: 'calc(50% - 10px)' };
    case 'large':
      return { minWidth: 'calc(75% - 10px)' };
    default:
      return {};
  }
};

export const mapAspectRatio = (aspectRatio?: string): CSSProperties => {
  if (
    !aspectRatio ||
    aspectRatio?.toLocaleLowerCase() === 'none' ||
    aspectRatio === ''
  )
    return {};
  return { aspectRatio: aspectRatio.replace(/:/g, '/') };
};

export const mapCTAsAlignment = (_alignment: string): CSSProperties => {
  switch (_alignment.toLowerCase()) {
    case 'center':
      return { alignItems: 'center', justifyContent: 'center' };
    case 'right':
      return { alignItems: 'flex-end', justifyContent: 'flex-end' };
    case 'left':
    default:
      return { alignItems: 'flex-start', justifyContent: 'flex-start' };
  }
};
